// src/components/InstructionsPopup.js

import React, { useEffect } from 'react';
import './InstructionsPopup.css';
import { FaTimes } from 'react-icons/fa';

const InstructionsPopup = ({ onClose, puzzleNumber }) => {
  useEffect(() => {
    // Add class to body to prevent scrolling when popup is open
    document.body.classList.add('no-scroll');

    return () => {
      // Remove class when popup is closed
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div
      className="instructions-popup"
      role="dialog"
      aria-modal="true"
      aria-labelledby="instructions-title"
    >
      <div className="instructions-content">
        <button className="close-button" onClick={onClose} aria-label="Close">
          <FaTimes />
        </button>
        <div className="logo-container">
          <img
            src="/Logonew1.png"
            alt="Words We Find Game Logo"
            className="game-logo"
          />
        </div>
        <h1 className="game-title" id="instructions-title">
          Words We Find
        </h1>
        <p className="puzzle-info">
          Puzzle #{puzzleNumber} - {new Date().toLocaleDateString()}
        </p>
        <h2>How to Play</h2>
        <p>Find the Daily Theme Word in 8 tries.</p>
        <ul className="instructions-list">
          <li>Unscramble 3 words</li>
          <li>Guess the connecting theme word</li>
        </ul>
        <h2>Understanding the Colors</h2>
        <div className="instructions-grid">
          {/* Scrambled Words Section */}
          <h3>Unscrambled Words</h3>
          <div className="grid-row">
            <div className="grid-cell correct">C</div>
            <div className="grid-cell">A</div>
            <div className="grid-cell">T</div>
          </div>
          <p>The letter C is in the correct position.</p>

          {/* Theme Word Section */}
          <h3>Theme Word</h3>
          <div className="grid-row">
            <div className="grid-cell">D</div>
            <div className="grid-cell present">O</div>
            <div className="grid-cell">G</div>
          </div>
          <p>The letter O is in the word but in a different position.</p>

          <div className="grid-row">
            <div className="grid-cell">B</div>
            <div className="grid-cell">I</div>
            <div className="grid-cell absent">R</div>
            <div className="grid-cell">D</div>
          </div>
          <p>The letter R is not in the word.</p>
        </div>
      </div>
    </div>
  );
};

export default InstructionsPopup;
