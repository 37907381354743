// src/components/GameBoard.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './GameBoard.css';
import { FaBackspace, FaShareAlt, FaTimes } from 'react-icons/fa';
import InstructionsPopup from './InstructionsPopup';

// Determine if the device is touch-capable
const isTouchDeviceGlobal =
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

// Helper function to get current local date in YYYY-MM-DD format
const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear(); // Local year
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Local month
  const day = String(today.getDate()).padStart(2, '0'); // Local day
  return `${year}-${month}-${day}`;
};

// Fixed start date for the game (Puzzle #1)
// Updated to start from 10/31/2024
const FIXED_START_DATE = '2024-10-31';

// Helper function to calculate puzzle number based on days since FIXED_START_DATE (local time)
const calculatePuzzleNumber = () => {
  const startDate = new Date(FIXED_START_DATE);
  const today = new Date();

  // Set both dates to local midnight to ensure accurate day difference
  const start = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const current = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const diffInTime = current.getTime() - start.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  return diffInDays + 1; // Puzzle numbers start from 1
};

// Helper functions to handle localStorage with per-date game states
const saveGameState = (state) => {
  const allStates =
    JSON.parse(localStorage.getItem('wordPuzzleGameStates')) || {};
  allStates[state.date] = state;
  localStorage.setItem('wordPuzzleGameStates', JSON.stringify(allStates));
};

const loadGameState = (date) => {
  const allStates =
    JSON.parse(localStorage.getItem('wordPuzzleGameStates')) || {};
  return allStates[date] || null;
};

const clearGameState = (date) => {
  const allStates =
    JSON.parse(localStorage.getItem('wordPuzzleGameStates')) || {};
  delete allStates[date];
  localStorage.setItem('wordPuzzleGameStates', JSON.stringify(allStates));
};

// Keyboard Component
const Keyboard = ({
  onKeyClick,
  letterStates,
  isDisabled,
  section,
  currentWordLetters,
  disabledLetters, // Added prop
}) => {
  const keyboardRows = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['DELETE', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'ENTER'],
  ];

  const getKeyLabel = (key) => {
    switch (key) {
      case 'ENTER':
        return 'Enter';
      case 'DELETE':
        return <FaBackspace className="delete-icon" />;
      default:
        return key;
    }
  };

  return (
    <div className="keyboard">
      {keyboardRows.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row">
          {row.map((key) => {
            let keyClass = 'key';

            if (key === 'ENTER' || key === 'DELETE') {
              keyClass += ' special-key';
            }

            if (letterStates[key]) {
              if (letterStates[key] === 'correct') {
                keyClass += ' correct';
              } else if (letterStates[key] === 'present') {
                keyClass += ' present';
              } else if (letterStates[key] === 'absent') {
                keyClass += ' absent';
              }
            }

            if (section === 'scrambled' && key.length === 1) {
              if (currentWordLetters.includes(key)) {
                keyClass += ' active-scrambled';
              } else {
                keyClass += ' inactive-scrambled';
              }
            }

            // Apply 'disabled-key' class if the key is in disabledLetters
            if (disabledLetters.includes(key)) {
              keyClass += ' disabled-key';
            }

            return (
              <button
                key={key}
                className={keyClass}
                onClick={() => onKeyClick(key)}
                aria-label={key}
                disabled={isDisabled} // Only disable based on isDisabled prop
              >
                {getKeyLabel(key)}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
};

// WordleGrid Component
const WordleGrid = ({
  guess,
  setGuess,
  scrambledWord,
  correctWord,
  isCorrect,
  handleSubmit,
  feedback,
  shakeTrigger,
  isGameOver,
  index,
  activeIndex,
  setActiveIndex,
  isTouchDevice,
}) => {
  const inputRefs = useRef([]);
  const [shaking, setShaking] = useState(false);

  useEffect(() => {
    if (feedback === 'incorrect' || feedback === 'incomplete') {
      setShaking(true);
      setTimeout(() => setShaking(false), 500);
    }
  }, [feedback, shakeTrigger]);

  useEffect(() => {
    if (activeIndex === index && !isGameOver && !isCorrect) {
      const firstEmptyIndex = guess.length;
      if (inputRefs.current[firstEmptyIndex]) {
        inputRefs.current[firstEmptyIndex].focus();
      }
    }
  }, [activeIndex, isGameOver, isCorrect, guess.length, index]);

  const handleInputFocus = () => {
    if (!isGameOver && !isCorrect) {
      setActiveIndex(index);
    }
  };

  return (
    <div
      className={`wordle-grid word-container ${shaking ? 'shake' : ''}`}
      onClick={handleInputFocus}
    >
      <div className="scrambled-grid">
        {scrambledWord.split('').map((letter, i) => (
          <div className="wordle-box-container" key={i}>
            <p className="scrambled-letter">{letter}</p>
            <input
              ref={(el) => (inputRefs.current[i] = el)}
              className={`wordle-box ${isCorrect ? 'correct wave' : ''}`}
              type="text"
              maxLength={1}
              value={guess[i] || ''}
              readOnly={true}
              tabIndex={isTouchDevice ? -1 : 0}
              inputMode="none"
              style={{
                animationDelay: isCorrect ? `${i * 0.1}s` : '0s',
                caretColor: 'transparent',
                backgroundColor: 'white',
                fontFamily: 'monospace',
                borderRadius: '0', // Ensure boxes are square
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

// WordleThemeGrid Component
const WordleThemeGrid = ({
  themeGuess,
  setThemeGuess,
  correctTheme,
  onSubmit,
  isCorrect,
  isGameOver,
  disabled,
  lockedInLetters,
  themeError,
  setActiveSection,
  isTouchDevice,
}) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (!isGameOver && !isCorrect && !disabled) {
      const firstEmptyIndex = themeGuess.findIndex(
        (letter, idx) => !letter && !lockedInLetters[idx]
      );
      if (firstEmptyIndex !== -1 && inputRefs.current[firstEmptyIndex]) {
        inputRefs.current[firstEmptyIndex].focus();
      }
    }
  }, [isGameOver, isCorrect, disabled, themeGuess, lockedInLetters]);

  const handleClick = () => {
    if (!isGameOver && !isCorrect && !disabled) {
      setActiveSection('theme');
    }
  };

  return (
    <div className="theme-grid-container" onClick={handleClick}>
      <div className="theme-grid">
        {[...Array(correctTheme.length)].map((_, i) => (
          <input
            key={i}
            ref={(el) => (inputRefs.current[i] = el)}
            className={`wordle-box ${lockedInLetters[i] ? 'correct' : ''}`}
            type="text"
            maxLength={1}
            value={lockedInLetters[i] || themeGuess[i] || ''}
            readOnly={true}
            tabIndex={isTouchDevice ? -1 : 0}
            inputMode="none"
            style={{
              caretColor: 'transparent',
              backgroundColor: 'white',
              fontFamily: 'monospace',
              borderRadius: '0', // Ensure boxes are square
            }}
          />
        ))}
      </div>
      {themeError && (
        <div className={`error-overlay ${themeError ? 'visible' : ''}`}>
          <p>{themeError}</p>
        </div>
      )}
    </div>
  );
};

const GameBoard = () => {
  // State variables
  const [scrambledWords, setScrambledWords] = useState([]);
  const [correctWords, setCorrectWords] = useState([]);
  const [theme, setTheme] = useState('');
  const [guesses, setGuesses] = useState(['', '', '']);
  const [correctWordGuesses, setCorrectWordGuesses] = useState([
    false,
    false,
    false,
  ]);
  const [themeGuess, setThemeGuess] = useState([]);
  const [feedback, setFeedback] = useState(['', '', '']);
  const [isThemeCorrect, setIsThemeCorrect] = useState(false);
  const [allWordsSolved, setAllWordsSolved] = useState(false);
  const [lockedInLetters, setLockedInLetters] = useState([]);
  const [showCongrats, setShowCongrats] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [timeTaken, setTimeTaken] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [showGameOver, setShowGameOver] = useState(false);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [themeError, setThemeError] = useState('');
  const [attemptedWords, setAttemptedWords] = useState([]);
  const [duplicateGuessError, setDuplicateGuessError] = useState('');
  const [attemptedThemeGuesses, setAttemptedThemeGuesses] = useState([]);
  const [shakeTriggers, setShakeTriggers] = useState([0, 0, 0]);
  const [keyboardLetterStates, setKeyboardLetterStates] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSection, setActiveSection] = useState('scrambled');
  const [showInstructions, setShowInstructions] = useState(false);
  const [statistics, setStatistics] = useState({
    gamesPlayed: 0,
    gamesWon: 0,
    currentStreak: 0,
    bestStreak: 0,
    perfectGames: 0,
  });
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [endMessage, setEndMessage] = useState('');
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [puzzleNumber, setPuzzleNumber] = useState(calculatePuzzleNumber());

  // Function to fetch daily puzzle based on local date
  const fetchDailyPuzzle = async (currentDate) => {
    try {
      const response = await fetch(`/api/daily?date=${currentDate}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data && data.scrambledWords.length > 0) {
        setScrambledWords(data.scrambledWords);
        setCorrectWords(data.correctWords);
        setTheme(data.theme);
        setLockedInLetters(Array(data.theme.length).fill(''));
        setThemeGuess(Array(data.theme.length).fill(''));
        setActiveIndex(0);
        setActiveSection('scrambled');
        setGuesses(['', '', '']);
        setCorrectWordGuesses([false, false, false]);
        setFeedback(['', '', '']);
        setIsThemeCorrect(false);
        setAllWordsSolved(false);
        setAttemptedWords([]);
        setDuplicateGuessError('');
        setAttemptedThemeGuesses([]);
        setKeyboardLetterStates({});
        setTotalAttempts(0);
        setIsGameOver(false);
        setShowGameOver(false);
        setShowCongrats(false);
        setEndMessage('');
        setPuzzleNumber(calculatePuzzleNumber()); // Ensure puzzle number is based on fixed start date
      }
    } catch (error) {
      console.error('Error fetching daily puzzle:', error);
    }
  };

  // Function to load game state from localStorage
  const loadSavedGame = (savedState) => {
    setScrambledWords(savedState.scrambledWords);
    setCorrectWords(savedState.correctWords);
    setTheme(savedState.theme);
    setLockedInLetters(savedState.lockedInLetters);
    setThemeGuess(savedState.themeGuess);
    setActiveIndex(savedState.activeIndex);
    setActiveSection(savedState.activeSection);
    setGuesses(savedState.guesses);
    setCorrectWordGuesses(savedState.correctWordGuesses);
    setFeedback(savedState.feedback);
    setIsThemeCorrect(savedState.isThemeCorrect);
    setAllWordsSolved(savedState.allWordsSolved);
    setAttemptedWords(savedState.attemptedWords);
    setDuplicateGuessError(savedState.duplicateGuessError);
    setAttemptedThemeGuesses(savedState.attemptedThemeGuesses);
    setKeyboardLetterStates(savedState.keyboardLetterStates);
    setTotalAttempts(savedState.totalAttempts);
    setIsGameOver(savedState.isGameOver);
    setShowGameOver(savedState.showGameOver);
    setShowCongrats(savedState.showCongrats);
    setEndMessage(savedState.endMessage);
    setTimeTaken(savedState.timeTaken);
    setStartTime(savedState.startTime);
    setActiveIndex(savedState.activeIndex);
    setActiveSection(savedState.activeSection);
    setStatistics(savedState.statistics || statistics);
    setPuzzleNumber(savedState.puzzleNumber);

    // If the game was completed, ensure the appropriate popup is shown
    if (savedState.isGameOver) {
      if (savedState.isThemeCorrect) {
        setShowCongrats(true);
      } else {
        setShowGameOver(true);
      }
      // Stop the timer if the game was over
      setStartTime(null);
    }
  };

  // Function to save game state to localStorage
  const saveCurrentGameState = useCallback(() => {
    const currentDate = getCurrentDate();
    const gameState = {
      date: currentDate,
      scrambledWords,
      correctWords,
      theme,
      guesses,
      correctWordGuesses,
      themeGuess,
      feedback,
      isThemeCorrect,
      allWordsSolved,
      lockedInLetters,
      attemptedWords,
      duplicateGuessError,
      attemptedThemeGuesses,
      keyboardLetterStates,
      totalAttempts,
      isGameOver,
      showGameOver,
      showCongrats,
      endMessage,
      timeTaken,
      startTime,
      activeIndex,
      activeSection,
      statistics,
      puzzleNumber,
    };
    saveGameState(gameState);
  }, [
    scrambledWords,
    correctWords,
    theme,
    guesses,
    correctWordGuesses,
    themeGuess,
    feedback,
    isThemeCorrect,
    allWordsSolved,
    lockedInLetters,
    attemptedWords,
    duplicateGuessError,
    attemptedThemeGuesses,
    keyboardLetterStates,
    totalAttempts,
    isGameOver,
    showGameOver,
    showCongrats,
    endMessage,
    timeTaken,
    startTime,
    activeIndex,
    activeSection,
    statistics,
    puzzleNumber,
  ]);

  // Load game state on component mount
  useEffect(() => {
    const currentDate = getCurrentDate();
    const savedState = loadGameState(currentDate);
    const savedStats = JSON.parse(localStorage.getItem('gameStatistics'));

    setPuzzleNumber(calculatePuzzleNumber());

    if (savedStats) {
      setStatistics(savedStats);
    }

    if (savedState) {
      loadSavedGame(savedState);

      // Calculate the time taken if the game is ongoing
      if (!savedState.isGameOver) {
        const currentTime = Date.now();
        const elapsedTime = Math.round((currentTime - savedState.startTime) / 1000);
        setTimeTaken(elapsedTime);
      } else {
        // If the game is over, ensure the timeTaken is set
        setTimeTaken(savedState.timeTaken);
        // Ensure the appropriate popup is shown
        if (savedState.isThemeCorrect) {
          setShowCongrats(true);
        } else {
          setShowGameOver(true);
        }
        // Stop the timer
        setStartTime(null);
      }
    } else {
      // New day or no saved state, start fresh
      clearGameState(currentDate);
      setStartTime(null);
      fetchDailyPuzzle(currentDate);
    }

    // Set timer to fetch new puzzle at next local midnight
    const now = new Date();
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0); // Next local midnight
    const msUntilMidnight = nextMidnight - now;

    const midnightTimer = setTimeout(() => {
      const newDate = getCurrentDate();
      fetchDailyPuzzle(newDate);
      setStartTime(null);
      setActiveIndex(0);
      setActiveSection('scrambled');
      setGuesses(['', '', '']);
      setCorrectWordGuesses([false, false, false]);
      setFeedback(['', '', '']);
      setIsThemeCorrect(false);
      setAllWordsSolved(false);
      setAttemptedWords([]);
      setDuplicateGuessError('');
      setAttemptedThemeGuesses([]);
      setKeyboardLetterStates({});
      setTotalAttempts(0);
      setIsGameOver(false);
      setShowGameOver(false);
      setShowCongrats(false);
      setEndMessage('');
      setPuzzleNumber(calculatePuzzleNumber());
      // Clear previous game state for the new day
      clearGameState(newDate);
    }, msUntilMidnight + 1000); // Add 1 second to ensure it's past midnight

    return () => clearTimeout(midnightTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save game state whenever relevant state variables change
  useEffect(() => {
    if (startTime !== null) {
      saveCurrentGameState();
    }
  }, [startTime, saveCurrentGameState]);

  // Save game state immediately when game is over
  useEffect(() => {
    if (isGameOver) {
      saveCurrentGameState();
    }
  }, [isGameOver, saveCurrentGameState]);

  // Update timeTaken every second if the game is ongoing
  useEffect(() => {
    if (!isGameOver && startTime !== null) {
      const timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = Math.round((currentTime - startTime) / 1000);
        setTimeTaken(elapsedTime);
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [isGameOver, startTime]);

  useEffect(() => {
    if (correctWordGuesses.every((isCorrect) => isCorrect)) {
      setAllWordsSolved(true);
      setActiveSection('theme');
    }
  }, [correctWordGuesses]);

  // Function to update statistics
  const updateStatistics = useCallback(
    (won, isPerfectGame) => {
      let {
        gamesPlayed,
        gamesWon,
        currentStreak,
        bestStreak,
        perfectGames,
      } = statistics;

      gamesPlayed += 1;

      // Check if the user played yesterday
      const lastPlayedDate = localStorage.getItem('lastPlayedDate');
      const today = getCurrentDate();

      if (lastPlayedDate && lastPlayedDate !== today) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toISOString().split('T')[0];

        if (lastPlayedDate === yesterdayStr) {
          // Continue streak
          if (won) {
            currentStreak += 1;
            if (currentStreak > bestStreak) {
              bestStreak = currentStreak;
            }
          } else {
            currentStreak = 0;
          }
        } else {
          // Missed a day, reset streak
          currentStreak = won ? 1 : 0;
        }
      } else {
        // First game or same day
        currentStreak = won ? currentStreak + 1 : 0;
        if (currentStreak > bestStreak) {
          bestStreak = currentStreak;
        }
      }

      if (won) {
        gamesWon += 1;
        if (isPerfectGame) {
          perfectGames += 1;
        }
      }

      const updatedStats = {
        gamesPlayed,
        gamesWon,
        currentStreak,
        bestStreak,
        perfectGames,
      };

      setStatistics(updatedStats);
      localStorage.setItem('gameStatistics', JSON.stringify(updatedStats));
      localStorage.setItem('lastPlayedDate', today);
    },
    [statistics]
  );

  // Function to trigger shake animation
  const triggerShake = useCallback(
    (index) => {
      const newShakeTriggers = [...shakeTriggers];
      newShakeTriggers[index] += 1;
      setShakeTriggers(newShakeTriggers);
    },
    [shakeTriggers]
  );

  // Function to handle guess change
  const handleGuessChange = useCallback(
    (index, value) => {
      const newGuesses = [...guesses];
      newGuesses[index] = value;
      setGuesses(newGuesses);
      setDuplicateGuessError(''); // Clear any previous duplicate guess error
    },
    [guesses]
  );

  // Function to handle submit guess
  const handleSubmitGuess = useCallback(
    (index) => {
      if (isGameOver) return;

      const guess = guesses[index].toLowerCase();

      // Check for incomplete guess
      if (guess.length < correctWords[index].length) {
        // Trigger shake animation
        setFeedback((prev) => {
          const newFeedback = [...prev];
          newFeedback[index] = 'incomplete';
          return newFeedback;
        });
        triggerShake(index);
        return;
      }

      const correctWord = correctWords[index].toLowerCase();

      if (guess === correctWord) {
        setCorrectWordGuesses((prev) => {
          const newCorrectWordGuesses = [...prev];
          newCorrectWordGuesses[index] = true;
          return newCorrectWordGuesses;
        });

        // Move to next unsolved word if exists
        const nextUnsolvedIndex = correctWordGuesses.findIndex(
          (isCorrect, idx) => !isCorrect && idx !== index
        );
        if (nextUnsolvedIndex !== -1) {
          setActiveIndex(nextUnsolvedIndex);
        } else {
          // No unsolved words left; useEffect will handle transitioning to theme
        }
      } else {
        // Check for duplicate guess in scrambled words
        if (attemptedWords.includes(guess)) {
          setDuplicateGuessError('Already attempted word');
          // Trigger shake animation
          triggerShake(index);
          return;
        } else {
          setDuplicateGuessError(''); // Clear any previous error
          setAttemptedWords([...attemptedWords, guess]);
        }

        setFeedback((prev) => {
          const newFeedback = [...prev];
          newFeedback[index] = 'incorrect';
          return newFeedback;
        });

        // Increment shake trigger
        triggerShake(index);

        // Increment totalAttempts only on wrong, new attempt
        setTotalAttempts((prev) => prev + 1);

        // Check for game over
        if (totalAttempts + 1 >= 8 && !isThemeCorrect) {
          // Game over
          setIsGameOver(true);
          setShowGameOver(true);

          // Reveal the correct words
          setCorrectWordGuesses([true, true, true]);
          setGuesses(correctWords.map((word) => word.toUpperCase()));

          // Reveal the theme
          setLockedInLetters(theme.toUpperCase().split(''));

          // Display "Good try" message
          setEndMessage('Good try');
          setShowEndMessage(true);

          // Stop the timer by clearing startTime
          setStartTime(null);

          // Transition to Game Over popup after a short delay
          setTimeout(() => {
            setShowGameOver(true);
            setShowEndMessage(false);
          }, 1500); // 1.5 seconds delay

          // Update statistics
          updateStatistics(false, false);

          // Save game state immediately
          saveCurrentGameState();
        }
      }

      // Save game state immediately when game is over
      if (isGameOver) {
        saveCurrentGameState();
      }
    },
    [
      isGameOver,
      guesses,
      correctWords,
      attemptedWords,
      triggerShake,
      setCorrectWordGuesses,
      correctWordGuesses,
      setActiveIndex,
      totalAttempts,
      isThemeCorrect,
      updateStatistics,
      theme,
      saveCurrentGameState,
    ]
  );

  // Function to handle theme submit
  const handleThemeSubmit = useCallback(() => {
    if (isThemeCorrect || isGameOver) return; // Prevent further submissions if game is over

    // Check for incomplete theme guess
    const incomplete = themeGuess.some(
      (letter, idx) => !letter && !lockedInLetters[idx]
    );
    if (incomplete) {
      setThemeError('Not enough letters');
      // Trigger shake animation
      triggerShake(0); // Assuming single theme section
      return;
    }

    // Combine locked in letters and current theme guess
    const combinedGuess = lockedInLetters
      .map((letter, index) => {
        return letter || (themeGuess[index] ? themeGuess[index] : '');
      })
      .join('');

    const guessLower = combinedGuess.toLowerCase();

    // Extract letters that are 'present' (misplaced) from keyboardLetterStates
    const requiredLetters = Object.keys(keyboardLetterStates).filter(
      (letter) => keyboardLetterStates[letter] === 'present'
    );

    // Check if all required letters are included in the guess
    const missingLetters = requiredLetters.filter(
      (letter) => !combinedGuess.includes(letter)
    );

    if (missingLetters.length > 0) {
      setThemeError('All misplaced letters must be used');
      return;
    } else {
      setThemeError('');
    }

    // Check for duplicate guess in theme
    if (attemptedThemeGuesses.includes(guessLower)) {
      setThemeError('Already attempted word');
      // Trigger shake animation
      triggerShake(0); // Assuming single theme section
      return;
    } else {
      setThemeError(''); // Clear any previous error
      setAttemptedThemeGuesses([...attemptedThemeGuesses, guessLower]);
    }

    // Check if the word is correct
    const cleanTheme = theme.toUpperCase();

    if (combinedGuess === cleanTheme) {
      // All letters are correctly guessed
      setIsThemeCorrect(true);
      setIsGameOver(true);
      setLockedInLetters(cleanTheme.split(''));
      const endTime = Date.now();
      const timeTakenSeconds = Math.round((endTime - startTime) / 1000); // Time in seconds
      setTimeTaken(timeTakenSeconds);

      // Display "Look at you go!" message
      setEndMessage('Look at you go!');
      setShowEndMessage(true);

      // Stop the timer by clearing startTime
      setStartTime(null);

      // Transition to Congratulations popup after a short delay
      setTimeout(() => {
        setShowCongrats(true);
        setShowEndMessage(false);
      }, 1500); // 1.5 seconds delay

      // Determine if it's a perfect game
      const isPerfectGame = totalAttempts === 0;
      updateStatistics(true, isPerfectGame);

      // Save game state immediately
      saveCurrentGameState();
    } else {
      // Validation logic to lock in correct letters and identify misplaced letters
      const newLockedInLetters = [...lockedInLetters];
      const guessedLetters = combinedGuess.split('');
      const themeLetters = cleanTheme.split('');

      // For updating keyboardLetterStates
      const newKeyboardLetterStates = { ...keyboardLetterStates };

      // For tracking letters that have been processed
      const letterCount = {};

      // First pass: Check for correct letters in the correct place
      for (let i = 0; i < themeLetters.length; i++) {
        const guessedLetter = guessedLetters[i];
        const correctLetter = themeLetters[i];

        if (guessedLetter === correctLetter) {
          newLockedInLetters[i] = correctLetter.toUpperCase();
          newKeyboardLetterStates[guessedLetter] = 'correct';
          letterCount[guessedLetter] = (letterCount[guessedLetter] || 0) + 1;
        }
      }

      // Second pass: Check for letters in the word but in the wrong place
      for (let i = 0; i < themeLetters.length; i++) {
        const guessedLetter = guessedLetters[i];
        if (newLockedInLetters[i] || !guessedLetter) continue;

        if (themeLetters.includes(guessedLetter)) {
          const totalOccurrences = themeLetters.filter(
            (letter) => letter === guessedLetter
          ).length;
          const currentCount = letterCount[guessedLetter] || 0;

          if (currentCount < totalOccurrences) {
            newKeyboardLetterStates[guessedLetter] =
              newKeyboardLetterStates[guessedLetter] !== 'correct'
                ? 'present'
                : 'correct';
            letterCount[guessedLetter] = currentCount + 1;
          } else {
            if (!newKeyboardLetterStates[guessedLetter]) {
              newKeyboardLetterStates[guessedLetter] = 'absent';
            }
          }
        } else {
          newKeyboardLetterStates[guessedLetter] = 'absent';
        }
      }

      setLockedInLetters(newLockedInLetters);
      setKeyboardLetterStates(newKeyboardLetterStates);

      // Clear themeGuess for next attempt
      setThemeGuess(Array(theme.length).fill(''));

      // Increment totalAttempts only on wrong, new attempt
      setTotalAttempts((prev) => prev + 1);

      // Check for game over
      if (totalAttempts + 1 >= 8) {
        // Game over
        setIsGameOver(true);
        setShowGameOver(true);

        // Reveal the theme
        setLockedInLetters(theme.toUpperCase().split(''));

        // Display "Good try" message
        setEndMessage('Good try');
        setShowEndMessage(true);

        // Stop the timer by clearing startTime
        setStartTime(null);

        // Transition to Game Over popup after a short delay
        setTimeout(() => {
          setShowGameOver(true);
          setShowEndMessage(false);
        }, 1500); // 1.5 seconds delay

        // Update statistics
        updateStatistics(false, false);

        // Save game state immediately
        saveCurrentGameState();
      }
    }

    // Save game state immediately when game is over
    if (isGameOver) {
      saveCurrentGameState();
    }
  }, [
    isThemeCorrect,
    isGameOver,
    themeGuess,
    lockedInLetters,
    keyboardLetterStates,
    theme,
    attemptedThemeGuesses,
    triggerShake,
    updateStatistics,
    startTime,
    totalAttempts,
    saveCurrentGameState,
  ]);

  // Function to handle key click
  const handleKeyClick = useCallback(
    (key) => {
      if (activeSection === 'scrambled') {
        const index = activeIndex;
        if (isGameOver || correctWordGuesses[index]) return;

        if (key === 'ENTER') {
          handleSubmitGuess(index);
        } else if (key === 'DELETE') {
          // Delete the last character
          const newGuess = guesses[index].slice(0, -1);
          handleGuessChange(index, newGuess);
        } else {
          // Add letter if length allows
          if (guesses[index].length < scrambledWords[index].length) {
            handleGuessChange(index, guesses[index] + key);
          }
        }
      } else if (activeSection === 'theme') {
        if (isGameOver || isThemeCorrect || !allWordsSolved) return;

        if (key === 'ENTER') {
          handleThemeSubmit();
        } else if (key === 'DELETE') {
          // Delete the last non-locked letter
          let index = themeGuess.length - 1;
          while (index >= 0 && (lockedInLetters[index] || !themeGuess[index])) {
            index--;
          }
          if (index >= 0 && !lockedInLetters[index]) {
            const newGuess = [...themeGuess];
            newGuess[index] = '';
            setThemeGuess(newGuess);
          }
        } else {
          // Regular letter key
          // Find the first empty, unlocked position in themeGuess
          let index = themeGuess.findIndex(
            (letter, idx) => !letter && !lockedInLetters[idx]
          );
          if (index !== -1) {
            const newGuess = [...themeGuess];
            newGuess[index] = key.toUpperCase();
            setThemeGuess(newGuess);
          }
        }
      }
    },
    [
      activeSection,
      activeIndex,
      isGameOver,
      correctWordGuesses,
      guesses,
      scrambledWords,
      handleGuessChange,
      handleSubmitGuess,
      handleThemeSubmit,
      isThemeCorrect,
      allWordsSolved,
      themeGuess,
      lockedInLetters,
    ]
  );

  // Function to format time
  const formatTime = useCallback((seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    if (hrs > 0) {
      return `${hrs} hour${hrs !== 1 ? 's' : ''} ${mins} minute${
        mins !== 1 ? 's' : ''
      } ${secs} second${secs !== 1 ? 's' : ''}`;
    } else {
      return `${mins} minute${mins !== 1 ? 's' : ''} ${secs} second${
        secs !== 1 ? 's' : ''
      }`;
    }
  }, []);

  // Function to get disabled letters for the keyboard
  const getDisabledLetters = useCallback(() => {
    if (activeSection === 'scrambled') {
      const currentScrambledWord = scrambledWords[activeIndex];
      if (currentScrambledWord) {
        const lettersInWord = currentScrambledWord.toUpperCase().split('');
        const uniqueLettersInWord = Array.from(new Set(lettersInWord));
        const allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        return allLetters.filter((letter) => !uniqueLettersInWord.includes(letter));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [activeSection, scrambledWords, activeIndex]);

  // Define current scrambled word letters for keyboard styling
  const currentScrambledWordLetters =
    activeSection === 'scrambled' && scrambledWords[activeIndex]
      ? Array.from(new Set(scrambledWords[activeIndex].toUpperCase().split('')))
      : [];

  // Function to close instructions and start timer
  const closeInstructions = useCallback(() => {
    setShowInstructions(false);
    if (startTime === null) {
      setStartTime(Date.now());
    }
  }, [startTime]);

  // Function to close splash screen
  const closeSplashScreen = useCallback(() => {
    setShowSplashScreen(false);
    setShowInstructions(true); // Show the "How to Play" popup after the splash screen
  }, []);

  // Function to clear error message after a delay
  useEffect(() => {
    if (duplicateGuessError || themeError) {
      const timer = setTimeout(() => {
        setDuplicateGuessError('');
        setThemeError('');
      }, 3000); // Dismiss after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [duplicateGuessError, themeError]);

  // Function to handle physical keyboard input for scrambled words
  const handleScrambledInput = useCallback(
    (e) => {
      if (isGameOver || correctWordGuesses[activeIndex]) return;

      const key = e.key.toUpperCase();

      if (key.length === 1 && /^[A-Z]$/.test(key)) {
        // Letter key pressed
        if (guesses[activeIndex].length < correctWords[activeIndex].length) {
          handleGuessChange(activeIndex, guesses[activeIndex] + key);
        }
        e.preventDefault();
      } else if (key === 'BACKSPACE' || key === 'DELETE') {
        if (guesses[activeIndex].length > 0) {
          // Delete the last character
          handleGuessChange(activeIndex, guesses[activeIndex].slice(0, -1));
        }
        e.preventDefault();
      } else if (key === 'ENTER') {
        handleSubmitGuess(activeIndex);
        e.preventDefault();
      }
    },
    [
      isGameOver,
      correctWordGuesses,
      activeIndex,
      guesses,
      correctWords,
      handleGuessChange,
      handleSubmitGuess,
    ]
  );

  useEffect(() => {
    if (
      activeSection === 'scrambled' &&
      !isGameOver &&
      !correctWordGuesses[activeIndex]
    ) {
      window.addEventListener('keydown', handleScrambledInput);
      return () => window.removeEventListener('keydown', handleScrambledInput);
    }
  }, [
    activeSection,
    handleScrambledInput,
    isGameOver,
    correctWordGuesses,
    activeIndex,
  ]);

  // Function to handle physical keyboard input for theme guess
  const handleThemeInput = useCallback(
    (e) => {
      if (isGameOver || isThemeCorrect || !allWordsSolved) return;

      const key = e.key.toUpperCase();

      if (key.length === 1 && /^[A-Z]$/.test(key)) {
        // Letter key pressed
        // Find the first empty, unlocked position
        let index = themeGuess.findIndex(
          (letter, idx) => !letter && !lockedInLetters[idx]
        );
        if (index !== -1) {
          const newGuess = [...themeGuess];
          newGuess[index] = key.toUpperCase();
          setThemeGuess(newGuess);
        }
        e.preventDefault();
      } else if (key === 'BACKSPACE' || key === 'DELETE') {
        // Delete the last non-locked letter
        let index = themeGuess.length - 1;
        while (index >= 0 && (lockedInLetters[index] || !themeGuess[index])) {
          index--;
        }
        if (index >= 0 && !lockedInLetters[index]) {
          const newGuess = [...themeGuess];
          newGuess[index] = '';
          setThemeGuess(newGuess);
        }
        e.preventDefault();
      } else if (key === 'ENTER') {
        handleThemeSubmit();
        e.preventDefault();
      }
    },
    [
      isGameOver,
      isThemeCorrect,
      allWordsSolved,
      themeGuess,
      lockedInLetters,
      setThemeGuess,
      handleThemeSubmit,
    ]
  );

  useEffect(() => {
    if (
      activeSection === 'theme' &&
      allWordsSolved &&
      !isThemeCorrect &&
      !isGameOver
    ) {
      window.addEventListener('keydown', handleThemeInput);
      return () => window.removeEventListener('keydown', handleThemeInput);
    }
  }, [activeSection, handleThemeInput, allWordsSolved, isThemeCorrect, isGameOver]);

  // Function to handle sharing results
  const handleShareResults = () => {
    const shareText = `I ${
      isThemeCorrect ? 'solved' : 'attempted'
    } today's Words We Find puzzle!

Attempts: ${totalAttempts}
Time Taken: ${formatTime(timeTaken)}

Can you beat me?`;

    if (navigator.share) {
      navigator
        .share({
          title: 'Words We Find',
          text: shareText,
          url: 'https://wordswefind.com',
        })
        .catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback for browsers that don't support the Share API
      navigator.clipboard
        .writeText(`${shareText}\n\nPlay now: https://wordswefind.com`)
        .then(() => alert('Results copied to clipboard!'))
        .catch((error) => console.error('Error copying to clipboard:', error));
    }
  };

  // Return statement and component rendering
  return (
    <div className="game-board">
      {/* Splash Screen */}
      {showSplashScreen && (
        <div className="splash-screen" role="dialog" aria-modal="true">
          <div className="splash-content">
            <div className="logo-container">
              <img
                src="/Logonew1.png"
                alt="Words We Find Game Logo"
                className="game-logo"
              />
            </div>
            <h1 className="game-title">Words We Find</h1>
            <p>
              Puzzle #{puzzleNumber} - {new Date().toLocaleDateString()}
            </p>
            <button onClick={closeSplashScreen}>Play</button>
          </div>
        </div>
      )}

      {/* Instructions Popup */}
      {showInstructions && (
        <InstructionsPopup onClose={closeInstructions} puzzleNumber={puzzleNumber} />
      )}

      {/* Look at you go! and Good try Messages */}
      {showEndMessage && (
        <div className={`game-message-overlay visible`}>
          <p>{endMessage}</p>
        </div>
      )}

      <h1>Words We Find</h1>

      {/* Game Components */}
      <>
        {/* Guesses Remaining Counter */}
        {!isGameOver && (
          <div className="guesses-remaining">
            Guesses Remaining: {8 - totalAttempts}
          </div>
        )}

        <div className="scrambled-words">
          {scrambledWords.length > 0 ? (
            scrambledWords.map((scrambledWord, index) => (
              <div key={index} className="wordle-word">
                <WordleGrid
                  guess={guesses[index]}
                  setGuess={(guess) => handleGuessChange(index, guess)}
                  scrambledWord={scrambledWord}
                  correctWord={correctWords[index]}
                  isCorrect={correctWordGuesses[index]}
                  feedback={feedback[index]}
                  handleSubmit={() => handleSubmitGuess(index)}
                  shakeTrigger={shakeTriggers[index]}
                  isGameOver={isGameOver}
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={(idx) => {
                    setActiveIndex(idx);
                    setActiveSection('scrambled');
                  }}
                  isTouchDevice={isTouchDeviceGlobal}
                />
              </div>
            ))
          ) : (
            <p>Loading scrambled words...</p>
          )}
        </div>
        {/* Separator Line */}
        <hr className="separator-line" />
        {/* Display Duplicate Guess Error for Scrambled Words */}
        {duplicateGuessError && (
          <div className={`error-overlay visible`}>
            <p>{duplicateGuessError}</p>
          </div>
        )}
        <div className="theme-guess">
          {/* Theme Header */}
          <div className="theme-header-container">
            <h2 className="theme-header">Guess the Theme</h2>
          </div>
          <WordleThemeGrid
            themeGuess={themeGuess}
            setThemeGuess={setThemeGuess}
            correctTheme={theme}
            onSubmit={handleThemeSubmit}
            isCorrect={isThemeCorrect}
            isGameOver={isGameOver}
            disabled={!allWordsSolved}
            lockedInLetters={lockedInLetters}
            themeError={themeError}
            setActiveSection={setActiveSection}
            isTouchDevice={isTouchDeviceGlobal}
          />
        </div>
        {/* Keyboard Component */}
        {!isGameOver && (
          <Keyboard
            onKeyClick={handleKeyClick}
            disabledLetters={getDisabledLetters()} // Properly used
            letterStates={keyboardLetterStates}
            isDisabled={isGameOver || isThemeCorrect}
            section={activeSection}
            currentWordLetters={currentScrambledWordLetters}
          />
        )}
      </>

      {/* Congratulations Popup */}
      {showCongrats && (
        <div className="congrats-popup">
          <div className="congrats-content">
            <button className="close-button" onClick={() => setShowCongrats(false)}>
              <FaTimes />
            </button>
            <h2>🎉 Congratulations! 🎉</h2>
            <p>
              You solved the daily mystery theme in {formatTime(timeTaken)}!
            </p>
            <p>Your Statistics:</p>
            <ul>
              <li>📈 Games Played: {statistics.gamesPlayed}</li>
              <li>🏆 Games Won: {statistics.gamesWon}</li>
              <li>
                📊 Win Percentage:{' '}
                {statistics.gamesPlayed
                  ? (
                      (statistics.gamesWon / statistics.gamesPlayed) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </li>
              <li>🔥 Current Streak: {statistics.currentStreak}</li>
              <li>🌟 Best Streak: {statistics.bestStreak}</li>
              <li>💯 Perfect Games: {statistics.perfectGames}</li>
            </ul>
            <button onClick={handleShareResults}>
              <FaShareAlt /> Share
            </button>
          </div>
        </div>
      )}
      {/* Game Over Popup */}
      {showGameOver && (
        <div className="gameover-popup">
          <div className="gameover-content">
            <button className="close-button" onClick={() => setShowGameOver(false)}>
              <FaTimes />
            </button>
            <h2>😢 Game Over 😢</h2>
            <p>The theme was: {theme.toUpperCase()}</p>
            <p>Your Statistics:</p>
            <ul>
              <li>📈 Games Played: {statistics.gamesPlayed}</li>
              <li>🏆 Games Won: {statistics.gamesWon}</li>
              <li>
                📊 Win Percentage:{' '}
                {statistics.gamesPlayed
                  ? (
                      (statistics.gamesWon / statistics.gamesPlayed) *
                      100
                    ).toFixed(2)
                  : 0}
                %
              </li>
              <li>🔥 Current Streak: {statistics.currentStreak}</li>
              <li>🌟 Best Streak: {statistics.bestStreak}</li>
              <li>💯 Perfect Games: {statistics.perfectGames}</li>
            </ul>
            <button onClick={handleShareResults}>
              <FaShareAlt /> Share
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameBoard;













































































